var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "config-modalidade new-tabs" },
    [
      _c("vertical-tabs-components", { attrs: { tabs: _vm.tabs } }, [
        _c(
          "div",
          {
            attrs: { slot: "tab-content-" + _vm.tabs[0].tab },
            slot: "tab-content-" + _vm.tabs[0].tab
          },
          [
            _c(
              "div",
              { staticClass: "vx-row" },
              [
                _c(
                  "vs-col",
                  { staticClass: "mt-5", attrs: { "vs-lg": "12" } },
                  [
                    _c("span", { staticClass: "mt-3" }, [
                      _vm._v("Modalidade "),
                      _c("b", { staticStyle: { color: "red" } }, [_vm._v("*")])
                    ]),
                    _c(
                      "el-select",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          disabled: "",
                          filterable: "",
                          placeholder: "Escolha a modalidade"
                        },
                        model: {
                          value: _vm.modalidadeSelecionada.ModalidadeId,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.modalidadeSelecionada,
                              "ModalidadeId",
                              $$v
                            )
                          },
                          expression: "modalidadeSelecionada.ModalidadeId"
                        }
                      },
                      _vm._l(_vm.modalidades, function(item) {
                        return _c("el-option", {
                          key: item.Id,
                          attrs: { label: item.Name, value: item.Id }
                        })
                      }),
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "vx-col w-full",
                    staticStyle: { "margin-top": "50px" }
                  },
                  [
                    _c("div", { staticClass: "card-gray" }, [
                      _c("div", { staticClass: "left" }, [
                        _c("span", { staticClass: "pl-3 bold" }, [
                          _vm._v(
                            "\n                  Renovação ao final da vigência\n                "
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "right" },
                        [
                          _c("vs-switch", {
                            staticClass: "mr-3",
                            model: {
                              value: _vm.modalidadeSelecionada.Ativo,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.modalidadeSelecionada,
                                  "Ativo",
                                  $$v
                                )
                              },
                              expression: "modalidadeSelecionada.Ativo"
                            }
                          }),
                          _c(
                            "label",
                            {
                              staticStyle: {
                                "font-size": "13px",
                                "margin-right": "12px"
                              },
                              attrs: { for: "" }
                            },
                            [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm.modalidadeSelecionada.Ativo
                                      ? "Ativado"
                                      : "Desativado"
                                  )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ])
                  ]
                ),
                _vm.modalidadeSelecionada.Ativo
                  ? _c(
                      "vs-row",
                      {
                        staticClass: "mt-5",
                        attrs: { "vs-type": "flex", "vs-justify": "end" }
                      },
                      [
                        _c("vs-divider", { attrs: { color: "dark" } }, [
                          _c("b", [_vm._v("Configuração")])
                        ]),
                        _c("el-alert", {
                          attrs: {
                            title: "Aviso",
                            type: "info",
                            closable: false,
                            description:
                              "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
                            "show-icon": ""
                          }
                        }),
                        _c(
                          "vs-col",
                          { staticClass: "mt-5", attrs: { "vs-lg": "4" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "12px"
                                }
                              },
                              [_vm._v("Máximo de vigência da proposta em dias")]
                            ),
                            _c("vs-input", {
                              staticClass: "w-full mt-3  input-50",
                              staticStyle: { height: "60px" },
                              model: {
                                value:
                                  _vm.modalidadeSelecionada
                                    .QtdMaximaDiasVigencia,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.modalidadeSelecionada,
                                    "QtdMaximaDiasVigencia",
                                    $$v
                                  )
                                },
                                expression:
                                  "modalidadeSelecionada.QtdMaximaDiasVigencia"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "vs-col",
                          { staticClass: "mt-5", attrs: { "vs-lg": "4" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "12px"
                                }
                              },
                              [
                                _vm._v(
                                  "Tempo para geração da proposta antes do final da vigência em dias"
                                )
                              ]
                            ),
                            _c("vs-input", {
                              staticClass: "w-full mt-3  input-50",
                              staticStyle: { height: "60px" },
                              model: {
                                value:
                                  _vm.modalidadeSelecionada
                                    .QtdDiasGeracaoPropostaEmailAviso,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.modalidadeSelecionada,
                                    "QtdDiasGeracaoPropostaEmailAviso",
                                    $$v
                                  )
                                },
                                expression:
                                  "modalidadeSelecionada.QtdDiasGeracaoPropostaEmailAviso"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "vs-col",
                          { staticClass: "mt-5", attrs: { "vs-lg": "4" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "12px"
                                }
                              },
                              [
                                _vm._v(
                                  "“Dias” Cancelamento Automático apólice anterior"
                                )
                              ]
                            ),
                            _c("vs-input", {
                              staticClass: "w-full mt-3  input-50",
                              staticStyle: { height: "60px" },
                              model: {
                                value:
                                  _vm.modalidadeSelecionada
                                    .QtdDiasApoliceCancelada,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.modalidadeSelecionada,
                                    "QtdDiasApoliceCancelada",
                                    $$v
                                  )
                                },
                                expression:
                                  "modalidadeSelecionada.QtdDiasApoliceCancelada"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "vs-col",
                          { staticClass: "mt-5", attrs: { "vs-lg": "12" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "12px"
                                }
                              },
                              [_vm._v("Escolha o modelo de emissão")]
                            ),
                            _c("br"),
                            _c("br"),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "1" },
                                on: {
                                  change: function($event) {
                                    return _vm.radioChange()
                                  }
                                },
                                model: {
                                  value: _vm.radio,
                                  callback: function($$v) {
                                    _vm.radio = $$v
                                  },
                                  expression: "radio"
                                }
                              },
                              [_vm._v("Anterior")]
                            ),
                            _c(
                              "el-radio",
                              {
                                attrs: { label: "2" },
                                on: {
                                  change: function($event) {
                                    return _vm.radioChange()
                                  }
                                },
                                model: {
                                  value: _vm.radio,
                                  callback: function($$v) {
                                    _vm.radio = $$v
                                  },
                                  expression: "radio"
                                }
                              },
                              [_vm._v("Posterior")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "vs-col",
                          { staticClass: "mt-5", attrs: { "vs-lg": "6" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "12px"
                                }
                              },
                              [
                                _vm._v(
                                  "“Dias” para Emissão da Apólice anterior ao final de\n                vigência"
                                )
                              ]
                            ),
                            _c("vs-input", {
                              staticClass: "w-full mt-3  input-50",
                              staticStyle: { height: "60px" },
                              attrs: { disabled: _vm.radio != "1" },
                              model: {
                                value:
                                  _vm.modalidadeSelecionada
                                    .QtdDiasEmissaoAvisoAnterior,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.modalidadeSelecionada,
                                    "QtdDiasEmissaoAvisoAnterior",
                                    $$v
                                  )
                                },
                                expression:
                                  "modalidadeSelecionada.QtdDiasEmissaoAvisoAnterior"
                              }
                            }),
                            _vm.radio == "1"
                              ? _c("hr", { staticClass: "line-radio" })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c(
                          "vs-col",
                          { staticClass: "mt-5", attrs: { "vs-lg": "6" } },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "bold",
                                  "font-size": "12px"
                                }
                              },
                              [
                                _vm._v(
                                  "“Dias” para Emissão da Apólice posterior ao final de\n                vigência"
                                )
                              ]
                            ),
                            _c("vs-input", {
                              staticClass: "w-full mt-3  input-50",
                              staticStyle: { height: "60px" },
                              attrs: { disabled: _vm.radio != "2" },
                              model: {
                                value:
                                  _vm.modalidadeSelecionada
                                    .QtdDiasEmissaoAvisoPosterior,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.modalidadeSelecionada,
                                    "QtdDiasEmissaoAvisoPosterior",
                                    $$v
                                  )
                                },
                                expression:
                                  "modalidadeSelecionada.QtdDiasEmissaoAvisoPosterior"
                              }
                            }),
                            _vm.radio == "2"
                              ? _c("hr", { staticClass: "line-radio" })
                              : _vm._e()
                          ],
                          1
                        ),
                        _c("vs-divider", { attrs: { color: "dark" } }, [
                          _c("b", [_vm._v("Flags")])
                        ]),
                        _c("el-alert", {
                          attrs: {
                            title: "Aviso",
                            type: "info",
                            closable: false,
                            description:
                              "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
                            "show-icon": ""
                          }
                        }),
                        _c(
                          "vs-col",
                          { staticClass: "mt-5", attrs: { "vs-lg": "12" } },
                          [
                            _c(
                              "div",
                              { staticClass: "flex mb-3" },
                              [
                                _c("vs-switch", {
                                  staticClass: "mr-3 ",
                                  model: {
                                    value:
                                      _vm.modalidadeSelecionada
                                        .EmissaoAutomatica,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.modalidadeSelecionada,
                                        "EmissaoAutomatica",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "modalidadeSelecionada.EmissaoAutomatica"
                                  }
                                }),
                                _c("label", [_vm._v("Emissão Automática")])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex mb-3" },
                              [
                                _c("vs-switch", {
                                  staticClass: "mr-3 ",
                                  model: {
                                    value:
                                      _vm.modalidadeSelecionada
                                        .CancelarApoliceOriginal,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.modalidadeSelecionada,
                                        "CancelarApoliceOriginal",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "modalidadeSelecionada.CancelarApoliceOriginal"
                                  }
                                }),
                                _c("label", [
                                  _vm._v("Cancelamento de Apólice original")
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex mb-3" },
                              [
                                _c("vs-switch", {
                                  staticClass: "mr-3 ",
                                  model: {
                                    value: _vm.modalidadeSelecionada.ISReajuste,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.modalidadeSelecionada,
                                        "ISReajuste",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "modalidadeSelecionada.ISReajuste"
                                  }
                                }),
                                _c("label", [
                                  _vm._v("Reajuste de IS pelo Índice monetário")
                                ])
                              ],
                              1
                            )
                          ]
                        ),
                        _vm.modalidadeSelecionada.ISReajuste
                          ? _c(
                              "vs-col",
                              { attrs: { "vs-lg": "12" } },
                              [
                                _c(
                                  "vs-row",
                                  [
                                    _c(
                                      "vs-col",
                                      { attrs: { "vs-lg": "6" } },
                                      [
                                        _c("span", { staticClass: "mt-3" }, [
                                          _vm._v("Tipo de Índice "),
                                          _c(
                                            "b",
                                            { staticStyle: { color: "red" } },
                                            [_vm._v("*")]
                                          )
                                        ]),
                                        _c(
                                          "el-select",
                                          {
                                            staticClass: "mt-3",
                                            attrs: {
                                              filterable: "",
                                              placeholder: "Escolha o tipo"
                                            },
                                            model: {
                                              value:
                                                _vm.modalidadeSelecionada
                                                  .IndiceId,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.modalidadeSelecionada,
                                                  "IndiceId",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "modalidadeSelecionada.IndiceId"
                                            }
                                          },
                                          _vm._l(_vm.indiceList, function(
                                            item
                                          ) {
                                            return _c("el-option", {
                                              key: item.Id,
                                              attrs: {
                                                label: item.Nome,
                                                value: item.Id
                                              }
                                            })
                                          }),
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.modalidadeSelecionada.IndiceId == 3
                                      ? _c(
                                          "vs-col",
                                          { attrs: { "vs-lg": "6" } },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "mt-3" },
                                              [
                                                _vm._v("Índice percentual % "),
                                                _c(
                                                  "b",
                                                  {
                                                    staticStyle: {
                                                      color: "red"
                                                    }
                                                  },
                                                  [_vm._v("*")]
                                                )
                                              ]
                                            ),
                                            _c("vs-input", {
                                              staticClass:
                                                "w-full mt-3 input-50",
                                              staticStyle: { height: "60px" },
                                              model: {
                                                value:
                                                  _vm.modalidadeSelecionada
                                                    .IndiceOutros,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.modalidadeSelecionada,
                                                    "IndiceOutros",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "modalidadeSelecionada.IndiceOutros"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "vs-row",
              {
                staticClass: "mt-5",
                attrs: { "vs-type": "flex", "vs-justify": "end" }
              },
              [
                _c(
                  "vs-col",
                  {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "end",
                      "vs-align": "center",
                      "vs-lg": "4",
                      "vs-sm": "12"
                    }
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { "margin-right": "10px" },
                        attrs: { round: "" },
                        on: {
                          click: function($event) {
                            return _vm.voltar()
                          }
                        }
                      },
                      [_vm._v("Voltar")]
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "button-primary text-center",
                        on: {
                          click: function($event) {
                            return _vm.update()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n              Salvar Alterações\n            "
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }